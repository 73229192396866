
export default function Error() {

  return (
    <div className=' bg-mobile-cover md:bg-desktop-cover  bg-fixed bg-center'>
            <div className='w-full h-full min-h-[450px] overflow-hidden relative'></div>

            <div className="bg-[rgba(0,0,0,.8)] pt-8 relative text-white">
                <h1 className="text-center text-4xl">此頁面並不存在 <br/><br/> This page is not existed</h1>
                <div className="flex justify-center mt-8">
                  <a href="https://www.patata.com.hk/zh"><button className="bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 mx-2 px-4 rounded">返回主頁</button></a>
                  <a href="https://www.patata.com.hk/en"><button className="bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 mx-2 px-4 rounded">Back to home page</button></a>
                </div>
                <div className="max-w-[1140px] py-16 px-5 mx-auto w-full">
                <div className='inline-block align-middle pb-9 text-white'>
                </div>

                </div>
            </div>

        </div>
  )
}
