import React, { useState, useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useParams } from "react-router-dom";
import { businessPartnersData } from "../components/Data";
import { getPosts } from "../services"
import DocumentMeta from 'react-document-meta';

export default function BusinessPartners() {
    const { lang } = useParams()
    const [businessPartners, setBusinessPartners] = useState([])
    const meta = {
        title: `Patata Kitchen Limited ${businessPartnersData[`heading_${lang}`]} -全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計|廚房裝修`,
        canonical: `https://www.patata.com.hk/${lang}/business-partners`,
        meta: {
            charset: 'utf-8',
            name: {
                title: `Patata Kitchen Limited - 全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計|廚房裝修`,
                keywords: `patata,kitchen,廚櫃專門店,廚櫃,廚櫃組合,廚櫃設計,廚房裝修`,
                description: `Patata Kitchen 擁有接近20年的廚櫃經驗，香港自設廠房，選用符合歐洲環保標準板材，五金配件採用歐洲進口品牌，我們精心製造，不懈追求完美。`,
            }
        }
    };

    useEffect(() => {
        getPosts('business-partners').then((result) => {
            setBusinessPartners(result)
        })
    }, [])
    return (
        <DocumentMeta {...meta}>
            <div className='bg-mobile-cover md:bg-desktop-cover  bg-contain'>
                <div className='w-full h-full min-h-[350px]  lg:min-h-[480px] xl:min-h-[620px] 2xl:min-h-[750px]  overflow-hidden relative flex justify-center items-center'>
                    <iframe className="w-2/3 md:w-1/2 aspect-video" src="https://www.youtube.com/embed/8hPI3fjiXxU" title="Patata 介紹" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className="bg-[rgba(0,0,0,.8)] relative text-white">
                    <h1 className='text-5xl text-center mb-8'>{lang === 'en' ? 'Professional Kitchen Cabinet Design Services' : '廚櫃設計服務'}</h1>
                    <div className="max-w-[1140px] py-16 px-5 mx-auto w-full">
                        <div className='inline-block align-middle pb-9 text-white'>
                            <h1>{businessPartnersData[`heading_${lang}`]}</h1>
                            <span>{businessPartnersData[`subHeading_${lang}`]}</span>
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-4 gap-5 md:gap-10">
                            {businessPartners.map((item, index) => {
                                return (
                                    <div className="flex flex-col gap-3">
                                        <Link to={item[`link_${lang}`]} className="block">
                                            <img className="w-full aspect-video" src={item.mainImage.asset.url} alt={`Patata Kitchen Limited - ${businessPartnersData[`heading_${lang}`]}`} />
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>
        </DocumentMeta>

    )
}
