import Carousel from "../components/Carousel/Slider"
import { useParams } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { slideArrList } from '../components/Data';
import DocumentMeta from 'react-document-meta';

export default function Home() {
  const { lang } = useParams()
  const meta = {
    title: `Patata Kitchen Limited - 全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計`,
    canonical: `https://www.patata.com.hk/${lang}`,
    meta: {
      charset: 'utf-8',
      name: {
        title: `Patata Kitchen Limited - 全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計`,
        keywords: `patata,kitchen,廚櫃專門店,廚櫃,廚櫃組合,廚櫃設計`,
        description: `Patata Kitchen 擁有接近20年的廚櫃經驗，香港自設廠房，選用符合歐洲環保標準板材，五金配件採用歐洲進口品牌，我們精心製造，不懈追求完美。`,
      }
    }
  };


  return (
    <DocumentMeta {...meta}>
      <div className='bg-mobile-cover md:bg-desktop-cover  bg-contain'>
        <div className='w-full h-full min-h-[350px]  lg:min-h-[480px] xl:min-h-[620px] 2xl:min-h-[750px] overflow-hidden relative flex items-center justify-center'>
        <iframe className="w-2/3 md:w-1/2 aspect-video" src="https://www.youtube.com/embed/8hPI3fjiXxU" title="Patata 介紹" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        </div>

        <div className="bg-[rgba(0,0,0,.8)] p-16 style text-white">
          <h1 className='text-5xl text-center mb-8'>{lang === 'en' ? 'Professional Kitchen Cabinet Design Services' : '廚櫃設計服務'}</h1>
          <div className='flex flex-col md:flex-row'>

            <div className='flex-1'>
              <Carousel data_arr={slideArrList.slide1} isShowNav={true} isShowPagination={true} isAutoplay={true} autoplayInterval="3" />

            </div>

            <div className='flex-1 flex justify-center items-center'>
              <div className='px-10 py-12'>
                <div className='flex flex-col items-center sm:items-start sm:flex-row gap-5'>
                  <div className='sm:w-1/2 md:text-center'>
                    <h2 className='text-4xl'>{lang === 'en' ? 'Professional Kitchen Design Services' : '專業廚房設計服務'}</h2>
                  </div>
                  <div className='sm:w-1/2 flex flex-col gap-5'>
                    <p></p>
                    <span>{lang === 'en' ? 'Professional designers follow up to ensure the perfect decoration project and design an ideal kitchen for guests.' : '專業設計師跟進確保完善裝修工程，設計一個客人最理想的廚房。'}</span>

                    <a className="flex items-center gap-3" href={`/${lang}/cases`} >
                      <ArrowRightIcon className='w-6 h-6' />
                      <span>{lang === 'en' ? 'More' : '顯示更多'}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col md:flex-row-reverse'>
            <div className='flex-1'>
              <Carousel data_arr={slideArrList.slide2} isShowNav={true} isShowPagination={true} isAutoplay={true} autoplayInterval="3" />
            </div>
            <div className='flex-1 flex justify-center items-center'>
              <div className='px-10 py-12'>
                <div className='flex flex-col items-center sm:items-start sm:flex-row gap-5'>
                  <div className='sm:w-1/2 md:text-center'>
                    <h2 className='text-4xl'>{lang === 'en' ? 'Variety of kitchen cabinets' : '多款廚櫃選擇'}</h2>
                  </div>
                  <div className='sm:w-1/2 flex flex-col gap-5'>
                    <p>
                      {lang === 'en' ? 'The store exhibition room displays a variety of kitchen cabinets for customers to choose or tailor a most suitable kitchen cabinet.' : '門市展覽室，展示多款廚櫃，供客人選擇或度身訂造一個最為合適的廚櫃。'}
                    </p>
                    <a className="flex items-center gap-3" href={`/${lang}/showroom`} >
                      <ArrowRightIcon className='w-6 h-6' />
                      <span>{lang === 'en' ? 'More' : '顯示更多'}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col md:flex-row'>
            <div className='flex-1'>
              <Carousel data_arr={slideArrList.slide3} isShowNav={true} isShowPagination={true} isAutoplay={true} autoplayInterval="3" />
            </div>
            <div className='flex-1 flex justify-center items-center'>
              <div className='px-10 py-12'>
                <div className='flex flex-col items-center sm:items-start sm:flex-row gap-5'>
                  <div className='sm:w-1/2 md:text-center'>
                    <h2 className='text-4xl'><span>{lang === 'en' ? 'Rich design experience' : '豐富設計經驗'}</span>
                    </h2>
                  </div>
                  <div className='sm:w-1/2 flex flex-col gap-5'>
                    <p>
                      {lang === 'en' ? "Patata Kitchen Limited's kitchen cabinet designer team has rich design experience, welcome to inquire and order or visit the store in person." : 'Patata Kitchen Limited 的廚櫃設計師團隊，擁有豐富的設計經驗，歡迎查詢訂購或親臨門市參觀。'}
                    </p>
                    <a className="flex items-center gap-3" href={`/${lang}/contact-us`} >
                      <ArrowRightIcon className='w-6 h-6' />
                      <span>{lang === 'en' ? 'More' : '顯示更多'}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  )
}










