import React, { useState } from 'react'
import './Slider.css'
import leftArrow from "./icons/left-arrow.svg";
import rightArrow from "./icons/right-arrow.svg";
import { useParams } from 'react-router-dom';

export default function Slider({ data_arr, isShowNav, isShowPagination, isBanner }) {
    const { lang } = useParams()

    const [slideIndex, setSlideIndex] = useState(1)

    const nextSlide = () => {
        if (slideIndex !== data_arr.length) {
            setSlideIndex(index => index + 1)
        }
        else if (slideIndex === data_arr.length) {
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(index => index - 1)
        }
        else if (slideIndex === 1) {
            setSlideIndex(data_arr.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }
    return (
        <div className={`container-slider ${isBanner ? 'banner':''}`}>
            {isBanner && (
                <div className="banner-triangle absolute bottom-0 left-0 right-0 z-[99]"></div>
            )}
            {isBanner && (
                <div className="absolute bottom-[8px] left-[72px] z-[99] uppercase rotate-[-8deg] text-[30px] text-white">
                    <h3>Geared towards designing</h3>
                    <h3 className='ml-[200px]'>your unique kitchen</h3>
                </div>
            )}

            {data_arr?.map((obj, index) => {
                return (
                    <div
                        key={index}
                        className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                        <img
                            alt="Patata Kitchen Limited" src={`/${obj.img}`}
                        />
                    </div>
                )
            })}
            {isShowNav &&
                <button
                    onClick={nextSlide}
                    className="btn-slide next"
                >
                    <img alt="right-arrow" src={rightArrow} />
                </button>
            }
            {isShowNav &&
                <button
                    onClick={prevSlide}
                    className="btn-slide prev"
                >
                    <img alt="left-arrow" src={leftArrow} />
                </button>
            }


            {/* <BtnSlider moveSlide={nextSlide} direction={"next"} />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/> */}
            {isShowPagination &&
                <div className="container-dots">
                    {data_arr?.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => moveDot(index + 1)}
                            className={slideIndex === index + 1 ? "dot active" : "dot"}
                        >

                        </div>
                    ))}
                </div>
            }

            {data_arr?.map((obj, index) => {
                return (
                    <div
                        key={index}
                        className={slideIndex === index + 1 ? "txt-container active" : "txt-container"}
                    >
                        <p className='text-xl'>{obj[`title_${lang}`]}</p>
                        <p className='text-white'>{obj[`subTitle_${lang}`]}</p>


                    </div>
                )
            })}
        </div>
    )
}
