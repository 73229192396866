import client from "../client"


export const getPostDetail = async (slug) => {
  const result =  client
  .fetch(
    `*[slug.current == "${slug}"] {
      title_zh,
      title_en,
      body_zh,
      body_en,
      categories[] -> {
        title_en,
        alias
      },
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      },
      images[] {
        asset -> {
          _id,
          url
        },

      }
    }`
  )
  return result;
};

export const getPosts = async (catAlias) => {
  const filterCondition = (catAlias) ? `&& *[_type == "category" && alias == "${catAlias}"][0]._id in categories[]._ref` : ''
  const result =  client
  .fetch(
    `
    *[_type=='post' ${filterCondition} ] | order(publishedAt desc){
      title_en,
      title_zh,
      slug,
      categories[0]->{alias, title_en},
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      },
      publishedAt
    }
    `
  )
  return result;
};


export const getCategories = async () => {
const result =  client
    .fetch(
    `*[_type == "category"] {
        title_en,
        title_zh,
        slug,
        alias
    }`
    )
return result;
};


export const getLatestPosts = async (limit, isImageNeeded = false) => {
  const filterCondition = (isImageNeeded) ? "mainImage {asset -> { _id, url }, alt},publishedAt," : ""
  const result =  client
      .fetch(
        `*[_type == "post"] | order(publishedAt desc) {title_en, ${filterCondition} categories[0]->{alias, title_en}} [0...${limit}]`
      )
  return result;
  };

