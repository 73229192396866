import React from 'react'
import { useParams } from 'react-router-dom'
import Error from '../pages/error'
import Footer from './Footer'
import Header from './Header'

export default function PageWrapper({ page }) {
    const { lang } = useParams()

    return (
        <>
            <Header />
            {lang === 'en' || lang === 'zh' ? (
                <>
                    {page}
                </>
            ) : (
                <Error />
            )}
            <Footer />
        </>
    )
}
