import {BrowserRouter, Routes ,Route, Navigate} from 'react-router-dom';
import {FloatingWhatsApp} from 'react-floating-whatsapp'
import Home from "./pages/home"
import ContactUs from "./pages/contact-us"
import Error from "./pages/error"
import avatar from './logo.svg'
import "./index.css";
import PageWrapper from './components/PageWrapper';
import Showroom from './pages/showroom';
import Cases from './pages/cases';
import CaseDetail from './pages/case-detail';
import BusinessPartners from './pages/business-partners';
import Brands from './pages/brands';


function App() {

  return (
    <BrowserRouter >
        <Routes>
          <Route path='/:lang'>
            <Route path="" element={<PageWrapper page={<Home />} />}/>
            <Route path="contact-us" element={<PageWrapper page={<ContactUs />} />}/>
            <Route path="business-partners" element={<PageWrapper page={<BusinessPartners />} />}/>
            <Route path="showroom" element={<PageWrapper page={<Showroom />} />}/>
            <Route path="brands" element={<PageWrapper page={<Brands />} />}/>
            <Route path="cases" element={<PageWrapper page={<Cases />} />}/>
            <Route path="cases/:detail" element={<PageWrapper page={<CaseDetail />} />}/>
          </Route>
          <Route path="/" element={<Navigate replace to="/zh" />} />
          <Route path="*" element={<PageWrapper page={<Error />} />}/>
        </Routes>
      {/*
      <FloatingWhatsApp
        className="z-20"
        phoneNumber='123456789'
        accountName='Patata Kitchen Limited'
        allowClickAway
        avatar={avatar}
        notification
        notificationDelay={30000}
        chatMessage={`Hello, May I help you?`}
      />
      */}

    </BrowserRouter>
  )
}

export default App
