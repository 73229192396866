import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useParams } from "react-router-dom";
import { showroomData } from "../components/Data";
import { getPosts } from "../services"
import DocumentMeta from 'react-document-meta';

export default function Showroom() {
    const { lang } = useParams()
    const [ showroom, setShowroom ] = useState([])
    useEffect(() => {
        getPosts('showroom').then((result)=>{
            setShowroom(result)
        })
    }, [])
    const meta = {
        title: `Patata Kitchen Limited ${showroomData[`heading_${lang}`]} - 全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計|廚房裝修`,
        canonical: `https://www.patata.com.hk/${lang}/showroom`,
        meta: {
          charset: 'utf-8',
          name: {
            title: `Patata Kitchen Limited - 全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計|廚房裝修`,
            keywords: `patata,kitchen,廚櫃專門店,廚櫃,廚櫃組合,廚櫃設計,廚房裝修`,
            description: `Patata Kitchen 擁有接近20年的廚櫃經驗，香港自設廠房，選用符合歐洲環保標準板材，五金配件採用歐洲進口品牌，我們精心製造，不懈追求完美。`,
          }
        }
      };




    const settings = {
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "0",
        slidesToShow: 1,
        speed: 500,
        rows: 2,
        slidesPerRow: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesPerRow: 2,

                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesPerRow: 1,
                    rows: 2

                }
            }

        ]
    };
    return (
        <DocumentMeta {...meta}>
      <div className='bg-mobile-cover md:bg-desktop-cover  bg-contain'>
        <div className='w-full h-full min-h-[350px]  lg:min-h-[480px] xl:min-h-[620px] 2xl:min-h-[750px]  overflow-hidden relative flex justify-center items-center'>
        <iframe className="w-2/3 md:w-1/2 aspect-video" src="https://www.youtube.com/embed/8hPI3fjiXxU" title="Patata 介紹" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
            <div className="bg-[rgba(0,0,0,.8)] p-16 style">
            <h1 className='text-5xl text-center mb-8 text-white'>{lang === 'en' ? 'Professional Kitchen Cabinet Design Services' : '廚櫃設計服務'}</h1>
                <div className='inline-block align-middle pb-9 text-white'>
                    <h1>{showroomData[`heading_${lang}`]}</h1>
                    <span>{showroomData[`subHeading_${lang}`]}</span>
                </div>
                <Slider {...settings}>
                    {showroom.map((item, index) => {
                        return (
                            <div key={index} className="text-left inline-block w-1/3 p-1">
                                <div className="overflow-hidden">
                                    <Link className="group" to={item[`link_${lang}`]}>
                                        <img className="aspect-video w-full block transition-all duration-300 group-hover:scale-105" src={item.mainImage.asset.url} alt={item[`title_${lang}`]} />
                                    </Link>
                                </div>
                                <div className="h-[100px] p-4 md:p-10">

                                    <h3 className="text-lg text-white font-light">
                                        <Link className="text-type-1 text-hover" to={item.link}>{item[`title_${lang}`]}</Link>
                                    </h3>
                                </div>

                            </div>
                        )
                    })}



                </Slider>
            </div>

        </div>
        </DocumentMeta>
    )
}
