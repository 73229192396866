import { ChevronDownIcon, MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useRef, useState } from "react";
import useDropdownFilter from "../components/useDropdownFilter";



const items = [
  { name: 'Item 1', category: 'Category 1' },
  { name: 'Item 2', category: 'Category 2' },
  { name: 'Item 3', category: 'Category 1' },
  { name: 'Item 4', category: 'Category 2' },
  { name: 'Item 4', category: 'Category 2' },
  { name: 'Item 4', category: 'Category 2' },
  { name: 'Item 4', category: 'Category 2' },
  { name: 'Item 4', category: 'Category 2' },
  { name: 'Item 4', category: 'Category 2' },
  { name: 'Item 4', category: 'Category 2' },
  { name: 'Item 4', category: 'Category 2' },
  { name: 'Item 4', category: 'Category 2' },
  { name: 'Item 4', category: 'Category 2' },
  { name: 'Item 4', category: 'Category 2' },
  { name: 'Item 4', category: 'Category 2' },
];


export default function Brands() {
  const searchInputRef = useRef(null)
  const [searchInput, setSearchInput] = useState('')
  const handleSearchOnChange = (e) => {
    setSearchInput(e.target.value)
  }
  const handleSearchClear = () => {
    setSearchInput('')
    searchInputRef.current.value = ''

  }
  return (
    <section className="bg-[#f6f5f2] flex gap-5">
      <div className="p-5 bg-[#e3e4e3] ">
        <div className='relative'>
          <MagnifyingGlassIcon className='w-4 h-4 absolute top-1/2 -translate-y-1/2 left-2' />
          {searchInput ?
            <PlusIcon onClick={handleSearchClear} className='cursor-pointer w-6 h-6 rotate-45 absolute top-1/2 -translate-y-1/2 right-2' />
            :
            null
          }

          <input placeholder='Search Patata' ref={searchInputRef} onChange={handleSearchOnChange} className='text-xs p-2 px-8 rounded-lg bg-[rgba(0,0,0,.2)]' />
        </div>
        <h2 className="py-4">Categories</h2>
        <div className="flex items-center justify-between">
          <p className="text-sm">Brands</p>
          <ChevronDownIcon className="w-2 h-2" />
        </div>
        <div className="flex items-center justify-between">
          <p className="text-sm">Appliance</p>
          <ChevronDownIcon className="w-2 h-2" />
        </div>
      </div>
      <div className="p-6 bg-[#e3e4e3] w-full">
        <div className="max-w-lg mx-auto">
          <img className="w-full" src="https://upload.wikimedia.org/wikipedia/commons/d/d0/Miele_Logo_M_Red_sRGB.png" />
        </div>
        <div className="flex items-center justify-between py-5">
          
        </div>
        <div className="grid grid-cols-9 gap-5">
          {items.map((item, index) => {
            return (
              <div key={index} className="rounded-2xl bg-white min-h-[130px] min-w-[130px] aspect-square"></div>
            )
          })}
        </div>
      </div>
    </section>
  );
}
