import React, { useState, useEffect, useRef } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";
import { casesData } from "../components/Data";
import { getPosts } from "../services"
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import DocumentMeta from 'react-document-meta';

export default function Cases() {
    const { lang } = useParams()
    const sliderRef = useRef(null)
    const [cases, setCases] = useState([])
    const [casesDup, setCasesDup] = useState([])
    const meta = {
        title: `Patata Kitchen Limited ${casesData[`heading_${lang}`]} - 全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計|廚房裝修`,
        canonical: `https://www.patata.com.hk/${lang}/cases`,
        meta: {
          charset: 'utf-8',
          name: {
            title: `Patata Kitchen Limited - 全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計|廚房裝修`,
            keywords: `patata,kitchen,廚櫃專門店,廚櫃,廚櫃組合,廚櫃設計,廚櫃設計個案,廚房裝修`,
            description: `Patata Kitchen 擁有接近20年的廚櫃經驗，香港自設廠房，選用符合歐洲環保標準板材，五金配件採用歐洲進口品牌，我們精心製造，不懈追求完美。`,
          }
        }
      };


    useEffect(() => {
        getPosts('cases').then((result) => {
            setCases(result)
            setCasesDup(result)
        })
    }, [])

    const handleInputChange = (keyword) => {
        if (keyword === '') {
            setCases(casesDup)
            sliderRef.current.slickGoTo(0, true)
        }else {
            setCases(casesDup.filter(item => item[`title_${lang}`].toLowerCase().includes(keyword.toLowerCase())))
        }
    }

    const settings = {
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "0",
        slidesToShow: 1,
        speed: 500,
        useTransform: false,
        rows: 2,
        slidesPerRow: 3,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesPerRow: 2,
              }
            },
            {
                breakpoint: 500,
                settings: {
                  slidesPerRow: 1,
                }
              },
          ],
          onInit: function () {
            setTimeout(()=>{
                sliderRef.current.slickGoTo(0, true)
            }, 400)

          }

    };

    return (
        <DocumentMeta {...meta}>

<div className='bg-mobile-cover md:bg-desktop-cover  bg-contain'>
        <div className='w-full h-full min-h-[350px]  lg:min-h-[480px] xl:min-h-[620px] 2xl:min-h-[750px]  overflow-hidden relative flex justify-center items-center'>
        <iframe className="w-2/3 md:w-1/2 aspect-video" src="https://www.youtube.com/embed/8hPI3fjiXxU" title="Patata 介紹" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>


            <div className="bg-[rgba(0,0,0,.8)] p-16 style">
            <h1 className='text-5xl text-center mb-8 text-white'>{lang === 'en' ? 'Professional Kitchen Cabinet Design Services' : '廚櫃設計服務'}</h1>
                <div className="relative flex mb-7 border-b-[1px] border-[rgba(255,255,255,.5)]"><MagnifyingGlassIcon className='text-white my-auto w-6 h-6' />
                    <input type="text" placeholder = {lang === 'en' ? 'Search Cases' : '搜尋案例'} onChange={(e) => handleInputChange(e.target.value)} className="w-full leading-[100%] text-[20px] text-white  bg-transparent h-[48px] outline-none pl-4" />
                </div>
                <div className='h-full flex items-end md:items-center'>
                    <div className='inline-block align-middle pb-9 text-white'>
                        <h1>{casesData[`heading_${lang}`]}</h1>
                        <span>{casesData[`subHeading_${lang}`]}</span>
                    </div>
                </div>
                <Slider {...settings} ref={sliderRef}>
                    {cases.map((item, index) => {
                        return (
                            <div key={index} className="align-top text-left inline-block w-1/3 p-1">
                                <div className="overflow-hidden">
                                    <a className="group" href={`cases/${item.slug.current}`}>
                                        <img className="aspect-video w-full block transition-all duration-300 group-hover:scale-105" src={item.mainImage.asset.url} alt={item[`title_${lang}`]} />
                                    </a>
                                </div>
                                <div className="h-[100px] bg-[#131417] p-4 md:p-10">

                                    <h3 className="text-lg font-light">
                                        <a className="text-type-1 text-white text-hover" href={`cases/${item.slug.current}`}>{item[`title_${lang}`]}</a>
                                    </h3>
                                </div>

                            </div>
                        )
                    })}



                </Slider>
            </div>
        </div>
        </DocumentMeta>

    )
}
