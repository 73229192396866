import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getPostDetail } from "../services"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Error from './error'
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import DocumentMeta from 'react-document-meta';

export default function CaseDetail() {
    var meta = []
    const {detail, lang } = useParams()
    const [postDetail, setPostDetail] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsLoading(true)
        getPostDetail(detail).then((result) => {
            setPostDetail(result)
            setIsLoading(false)
        })

    }, [])
    if(!isLoading){
        if (postDetail.length === 0) {
            return <Error />
        } else {
            meta = {
                title: `${postDetail[0][`title_${lang}`]} - Patata Kitchen Limited - 全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計|廚房裝修`,
                canonical: `https://www.patata.com.hk/${lang}/cases/${detail}`,
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: `patata,kitchen,廚櫃專門店,廚櫃,廚櫃組合,廚櫃設計,廚房裝修,${postDetail[0][`title_${lang}`]},${detail}`,
                        title: `${postDetail[0][`title_${lang}`]} - Patata Kitchen Limited - 全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計|廚房裝修`,
                        description: `${postDetail[0][`title_${lang}`]} - Patata Kitchen 擁有接近20年的廚櫃經驗，香港自設廠房，選用符合歐洲環保標準板材，五金配件採用歐洲進口品牌，我們精心製造，不懈追求完美。`,
                    }
                }
            };
        }
    }


    return (
        <DocumentMeta {...meta}>

<div className='bg-mobile-cover md:bg-desktop-cover  bg-contain'>
        <div className='w-full h-full min-h-[350px]  lg:min-h-[480px] xl:min-h-[620px] 2xl:min-h-[750px]  overflow-hidden relative flex justify-center items-center'>
        <iframe className="w-2/3 md:w-1/2 aspect-video" src="https://www.youtube.com/embed/8hPI3fjiXxU" title="Patata 介紹" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

                <div className='bg-[rgba(0,0,0,.8)] py-8 px-5 md:p-12 min-h-screen'>
                    {isLoading ? (
                        <div>{lang === 'en' ? 'Loading...' : '載入中...'}</div>
                    ) :
                        <div>
                            <div className=" relative text-white">

                                <div className=" py-16 px-5 mx-auto w-full">
                                    <a className='text-white flex mb-4' href={`/${lang}/cases`}>
                                        <ArrowLeftIcon className='w-6 h-6 mr-2' />
                                        {lang === 'en' ? 'Back to Cases' : '返回案例分享'}
                                    </a>
                                    <h2 className='text-3xl text-white mb-8'>{postDetail[0][`title_${lang}`]}</h2>
                                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-5 md:gap-10">
                                        <img className='w-full block mb-9' src={postDetail[0].mainImage.asset.url} alt={`${postDetail[0][`title_${lang}`]} - 完工圖`} />
                                        {postDetail[0].images?.map((item, index) => {
                                            return (
                                                <div key={index} className="flex flex-col gap-3">
                                                    <img className='w-full h-full aspect-video object-contain' src={item.asset.url} alt={`${postDetail[0][`title_${lang}`]} - 完工圖`}  />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </DocumentMeta>

    )
}
