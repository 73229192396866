import Form from "../components/Form";
import { commonContact } from "../components/Data";
import DocumentMeta from 'react-document-meta';
import { Link, useParams } from "react-router-dom";
export default function ContactUs() {
   const { lang } = useParams()

   const meta = {
      title: `Patata Kitchen Limited - 全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計|廚房裝修`,
      canonical: `https://www.patata.com.hk/${lang}/contact-us`,
      meta: {
        charset: 'utf-8',
        name: {
          title: `Patata Kitchen Limited - 全港最用心設計的廚櫃專門店|廚櫃組合|廚櫃設計|廚房裝修`,
          keywords: `patata,kitchen,廚櫃專門店,廚櫃,廚櫃組合,廚櫃設計,廚房裝修`,
          description: `Patata Kitchen 擁有接近20年的廚櫃經驗，香港自設廠房，選用符合歐洲環保標準板材，五金配件採用歐洲進口品牌，我們精心製造，不懈追求完美。`,
        }
      }
    };


   return (
      <DocumentMeta {...meta}>
      <div className='bg-mobile-cover md:bg-desktop-cover  bg-contain'>
        <div className='w-full h-full min-h-[350px]  lg:min-h-[480px] xl:min-h-[620px] 2xl:min-h-[750px]  overflow-hidden relative flex justify-center items-center'>
        <iframe className="w-2/3 md:w-1/2 aspect-video" src="https://www.youtube.com/embed/8hPI3fjiXxU" title="Patata 介紹" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
         <div className="bg-[rgba(0,0,0,.8)] relative text-white">
         <h1 className='text-5xl text-center mb-8'>{lang === 'en' ? 'Professional Kitchen Cabinet Design Services' : '廚櫃設計服務'}</h1>

            <div className="max-w-[1140px] py-12 px-5 mx-auto w-full">
               <h1
                  className="
                  mb-4
                  uppercase
                  font-bold
                  text-xl
                     "
               >
                  {lang === 'en' ? 'Contact Us' : '聯絡我們'}
               </h1>
               <div className="flex flex-wrap lg:justify-between -mx-4">
                  <div className="w-full lg:w-1/2 xl:w-6/12 px-4">
                     <div className="max-w-[570px] mb-12 lg:mb-0">
                        <h2 className="mb-4">PATATA Kitchen Limited</h2>


                        {commonContact.map((item, index) => (
                           <div key={index} className="flex mb-2 max-w-[370px] w-full">
                              <div className="
                              max-w-[60px]
                              sm:max-w-[70px]
                              w-full
                              flex
                              items-center
                              justify-center
                              mr-6
                              overflow-hidden
                              bg-[#3056d3] bg-opacity-5
                              text-[#3056d3]
                              rounded
                              " >
                                 <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                              </div>
                              <div className="w-full">
                                 <p className="text-base text-body-color">
                                    {item[`name_${lang}`]}
                                 </p>
                              </div>
                           </div>
                        ))}
                        <div className="mt-4"><div className="gmap_canvas"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3692.005037933354!2d114.175966!3d22.277799!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040137aa437ea9%3A0x9129da5803017447!2sPatata%20Kitchen%20Limited!5e0!3m2!1szh-TW!2sus!4v1708969277882!5m2!1szh-TW!2sus" width="350" height="250" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe></div></div>
                     </div>
                  </div>
                  <Form />
               </div>
            </div>
         </div>

      </div>
      </DocumentMeta>
   )
}




