import { EnvelopeIcon, PhoneIcon, MapPinIcon } from '@heroicons/react/24/solid';
export const formQuestions = [
    {
       htmlTag: "input",
       rows: "",
       type: "text",
       placeholder: "稱呼 Name",
       name: "name",
       validations: {required: true},
       errorMessages: {required: "請填寫此欄"}
    },
    {
       htmlTag: "input",
       rows: "",
       type: "email",
       placeholder: "電郵地址 Email Address",
       name: "email",
       validations: {required: true, pattern: /^\S+@\S+$/i},
       errorMessages: {required: "請填寫此欄", pattern: "請填寫正確的電郵格式"},
    },
    {
       htmlTag: "input",
       rows: "",
       type: "tel",
       placeholder: "聯絡電話 Phone Number",
       name: "phone",
       validations: {required: true, pattern: /[0-9]/},
       errorMessages: {required: "請填寫此欄", pattern: "請輸入正確的電話號碼 (只需數字)"},
    },
    {
        htmlTag: "textarea",
        rows: "6",
        type: "message",
        placeholder: "留言 Your Message",
        name: "message",
        validations: {required: true, maxLength: 200},
        errorMessages: {required: "請填寫此欄", maxLength: "字數上限為200字"},
     }
 ]

export const commonInfo =
    {
        phone: "+852 2892 2231",
        email: "info@patata.com.hk",
    }


  export const commonContact = [
    { name_zh: '聯絡電話：'+commonInfo.phone, name_en:'Phone No.:'+commonInfo.phone, href: '#', icon: PhoneIcon },
    { name_zh: '電郵：'+commonInfo.email, name_en:'Email:'+commonInfo.phone, href: '#', icon: EnvelopeIcon },
    { name_zh: '地址：新界荃灣青山道459-469號華力工業中心9樓E室', name_en:'Address: Flat E, 9/F, Wah Lik Industrial Centre 459-459 Castle Peak Road, Tsuen Wan, N. T.', href: '#', icon: EnvelopeIcon },
  ]




  export const DigitalMarketingSwiperList = [
    {
      thumbnail: "https://webertop.oos.webersite.top/u_354298506162343936/202112061726389.png",
      heading: "None營銷活動報名"

    },
    {
      thumbnail: "https://webertop.oos.webersite.top/u_354298506162343936/202112061741944.png",
      heading: "婚宴婚禮攝影"

    },
    {
      thumbnail: "https://webertop.oos.webersite.top/u_354298506162343936/202112061759387.png",
      heading: "地產建築"

    },
    {
      thumbnail: "https://webertop.oos.webersite.top/u_354298506162343936/202112061727165.png",
      heading: "潔淨能源太陽能"

    },
    {
      thumbnail: "https://webertop.oos.webersite.top/u_354298506162343936/202112061727165.png",
      heading: "潔淨能源太陽能"

    },
    {
      thumbnail: "https://webertop.oos.webersite.top/u_354298506162343936/202112061727165.png",
      heading: "潔淨能源太陽能"

    },
    {
      thumbnail: "https://webertop.oos.webersite.top/u_354298506162343936/202112061741804.png",
      heading: "潔淨能源太陽能"

    },
    {
      thumbnail: "https://webertop.oos.webersite.top/u_354298506162343936/202112061759261.png",
      heading: "潔淨能源太陽能"

    },

  ];
  export const headerData = {
    heading_en: 'PATATA Kitchen Limited',
    heading_zh: 'PATATA Kitchen Limited',
    nav:[
      {
        navText_en: 'Home',
        navText_zh: '主頁',
        link: ''
      },
      {
        navText_en: 'Showroom',
        navText_zh: '展覽室',
        link: 'showroom'
      },
      {
        navText_en: 'Cases',
        navText_zh: '案例分享',
        link: 'cases'
      },
      {
        navText_en: 'Business Partners',
        navText_zh: '合作夥伴',
        link: 'business-partners'
      },
      {
        navText_en: 'Contact Us',
        navText_zh: '聯絡我們',
        link: 'contact-us'
      }
    ]


  }
  export const footerData = {
    iconList: [
      {
        iconText_en: 'Shop Address',
        iconText_zh: '門市地址',
        icon: MapPinIcon,
        link: 'https://www.google.com/maps/place/Patata+Kitchen+Limited/@22.2775813,114.180893,19z/data=!3m1!4b1!4m6!3m5!1s0x34040137aa437ea9:0x9129da5803017447!8m2!3d22.2775813!4d114.1814402!16s%2Fg%2F11sbb7kprf'
      },
      {
        iconText_en: 'Email Us',
        iconText_zh: '電郵',
        icon: EnvelopeIcon,
        link: 'mailto:info@patata.com.hk'
      },
      {
        iconText_en: 'Contact Us',
        iconText_zh: '致電我們',
        icon: PhoneIcon,
        link: 'tel:28922231'
      }
    ]
  }
  export const slideArrList = {
    slide1: [
      {
        title_en: "",
        subTitle_en: "",
        title_zh: "",
        subTitle_zh: "",
        img: 'images/slide1-img1.jpg'
      }
    ],
    slide2: [
      {
        title_en: "",
        subTitle_en: "",
        title_zh: "",
        subTitle_zh: "",
        img: 'images/slide2-img1.jpg'
      },
      {
        title_en: "",
        subTitle_en: "",
        title_zh: "",
        subTitle_zh: "",
        img: 'images/slide2-img2.jpg'
      },
      {
        title_en: "",
        subTitle_en: "",
        title_zh: "",
        subTitle_zh: "",
        img: 'images/slide2-img3.jpg'
      }
    ],
    slide3: [
      {
        title_en: "",
        subTitle_en: "",
        title_zh: "",
        subTitle_zh: "",
        img: 'images/slide3-img1.jpg'
      }
    ]
  }
  export const casesData = {
    heading_en: 'Cases',
    heading_zh: '案例分享',
    subHeading_en: '',
    subHeading_zh: '',
  }

  export const businessPartnersData = {
    heading_en: 'Business Partners',
    heading_zh: '合作夥伴',
    subHeading_en: '',
    subHeading_zh: '',
  }

  export const showroomData = {
    heading_en: 'Showroom',
    heading_zh: '展覽室',
    subHeading_en: '',
    subHeading_zh: '',
  }